.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.scrollContainer {
    display: grid;
    grid-auto-flow: column;
    gap: 12px;
    overflow-x: auto;
    grid-auto-columns: calc(60vw/3 - 28px);
    height: calc((60vw/2.5) + 48px);
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    scroll-behavior: smooth;
}

.productItemContainer {
    height: calc((60vw/2.5) + 30px);
}

.productItemContainer p {
    margin-bottom: 0px !important;
    font-size: 14px;
}

.arrowBtnContainer {
    position: absolute;
    z-index: 1;
    top: 38%;
    transform: translateY(-50%);
}

.scrollContainer::-webkit-scrollbar {
    display: none;
}

@media screen and (min-width: 1600px) {
    .scrollContainer {
        grid-auto-columns: 385px;
    }
}

@media screen and (max-width: 900px) {
    .scrollContainer {
        grid-auto-columns: 256px;
        height: calc((256px*4/3) + 48px);
    }

    .productItemContainer {
        height: calc((256px*4/3) + 30px);
    }

    .arrowBtnContainer {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .scrollContainer {
        grid-auto-columns: calc(100vw/2);
        height: calc((100vw*2/3) + 50px);
    }

    .productItemContainer {
        height: calc((100vw*2/3) + 32px);
    }
}